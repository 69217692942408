import {
  BALANCE_SUCCESS,
  LOGIN_SUCCESS,
  LOGOUT,
  EXPOSURE_AMOUNT,
} from "./types";

const initialState = {
  isAuth: localStorage.getItem("token") !== null,
  token:
    localStorage.getItem("token") !== null
      ? localStorage.getItem("token")
      : null,
  user:
    localStorage.getItem("user") !== null
      ? JSON.parse(localStorage.getItem("user"))
      : null,
  wallet:
    localStorage.getItem("wallet") !== null
      ? JSON.parse(localStorage.getItem("wallet"))
      : null,
  exposureAmount:
    localStorage.getItem("exposureAmount") !== null
      ? localStorage.getItem("exposureAmount")
      : null,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_SUCCESS:
      localStorage.setItem("token", payload.token);
      localStorage.setItem("user", JSON.stringify(payload.user));
      return {
        ...state,
        isAuth: true,
        token: payload.token,
        user: payload.user,
      };
    case BALANCE_SUCCESS:
      delete payload.status;
      localStorage.setItem("wallet", JSON.stringify(payload));
      return {
        ...state,
        wallet: payload,
      };
    case EXPOSURE_AMOUNT:
      localStorage.setItem("exposureAmount", payload);
      return {
        ...state,
        exposureAmount: payload,
      };
    case LOGOUT:
      // localStorage.clear();
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("wallet");
      localStorage.removeItem("exposureAmount");
      return {
        ...state,
        isAuth: false,
      };
    default:
      return state;
  }
};
