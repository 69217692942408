import React from "react";

export default function ProfileIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.499"
      height="21.773"
      viewBox="0 0 23.499 21.773"
    >
      <g id="Group_541" data-name="Group 541" transform="translate(-302 -23)">
        <g id="Icon" transform="translate(302 23)">
          <path
            id="Shape"
            d="M5.732,13.756C2.571,13.756,0,10.67,0,6.877S2.571,0,5.732,0s5.732,3.085,5.732,6.877S8.892,13.756,5.732,13.756Zm0-12.036a3.6,3.6,0,0,0-2.838,1.5A5.948,5.948,0,0,0,1.719,6.877a5.948,5.948,0,0,0,1.174,3.654,3.6,3.6,0,0,0,2.838,1.5,3.6,3.6,0,0,0,2.837-1.5A5.949,5.949,0,0,0,9.743,6.877,5.949,5.949,0,0,0,8.569,3.224,3.6,3.6,0,0,0,5.732,1.719Z"
            transform="translate(6.018 0)"
            fill="#ff6201"
          />
          <path
            id="Shape-2"
            data-name="Shape"
            d="M16.993,0a.864.864,0,0,0-.172,1.719,5.582,5.582,0,0,1,4.918,5.158H1.76A5.628,5.628,0,0,1,6.677,1.724.864.864,0,1,0,6.505,0,7.436,7.436,0,0,0,0,7.736a.86.86,0,0,0,.86.86H22.639a.86.86,0,0,0,.86-.86A7.436,7.436,0,0,0,16.993,0Z"
            transform="translate(0 13.178)"
            fill="#ff6201"
          />
        </g>
      </g>
    </svg>
  );
}
